<template>
    <ui-modal ref="bulkLoadModal" :title="title" centered @hidden="clearForm">
        <label for="reason" class="form-label">
            Select the file to upload:
        </label>
        <input
            id="file"
            ref="inputFile"
            name="file"
            type="file"
            class="form-control custom-textarea"
            placeholder="Select your file"
            accept=".csv"
            :class="[{ 'is-invalid': v$.file.$dirty && v$.file.$error, 'is-valid':  v$.file.$dirty && !v$.file.$error }]"
            @change="change"
        />
        <div v-if="v$.file.$error" class="invalid-feedback">
            {{v$.file.$errors[0].$message}}
        </div>
        <small class="d-block mt-2">You must select a file with the .csv extension.</small>
        <template #footer>
            <ui-button variant="light" @click="hide">
                Close
            </ui-button>
            <ui-button variant="primary" :loading="loading" @click="uploadChargebacks">
                Continue
            </ui-button>
        </template>
    </ui-modal>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'

import UiButton from '@/components/ui/buttons/Button.vue';
import UiModal from '@/components/ui/Modal.vue';

export default {
    name: 'BulkLoadModal',
    components: { UiButton, UiModal },
    props: {
        method: {
            type: String,
            default: null,
            required: true,
        },
        title: {
            type: String,
            default: 'Bulk upload',
            required: false,
        },
        url: {
            type: String,
            default: null,
            required: true,
        },
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            loading: false,
            file: [],
        };
    },
    validations () {
        return {
            file: {
                required: requiredIf(function () {
                    return this.file.length == 0;
                }),
                $autoDirty: true,
            },
        };
    },
    mounted() {
        this.modal = this.$refs.bulkLoadModal;
    },
    methods: {
        show() {
            this.modal.show();
        },
        hide() {
            this.modal.hide();
        },
        change(event) {
            this.file = event.target.files[0];
        },
        clearForm() {
            this.file = null;
            this.$refs.inputFile.value = null;
            this.v$.$reset();
        },
        async uploadChargebacks() {
            const validated = await this.v$.$validate();
    
            if (!validated) return;
    
            try {
                this.loading = true;

                const formData = new FormData();
                formData.append('file', this.file);
                
                const { data } = await this.$axios.post(`/_/files/tmp`, formData, { 'Content-Type': 'multipart/form-data' });
                await this.$axios({
                    url: this.url,
                    method: this.method,
                    data: { file_id: data.id },
                });

                this.$emit('bulkUploadCompleted')
                this.$toast.success('Bulk upload completed successfully');
                this.hide();
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.loading = false;
            }
        },
    },
}
</script>
