<template>
    <div class="chargebacks-list">
        <ui-table-skeleton v-if="loading" />
        <template v-else>
            <div class="chargebacks-list row g-2 mb-4">
                <div class="col-md-2">
                    <ui-input
                        id="search"
                        name="search"
                        label="Search chargebacks"
                        type="text"
                        placeholder="Enter keywords"
                        :debounce="500"
                        v-model:value="filters.q"
                    >
                        <template #prepend>
                            <span class="fas fa-search"></span>
                        </template>
                    </ui-input>
                </div>
                <template v-if="_available_filters">
                    <div class="col-md-auto">
                        <date-range-picker
                            ref="picker"
                            v-model="range"
                            opens="right"
                            time-picker
                            time-picker-24-hours
                            show-dropdowns
                            control-container-class="chargebacks-list__date-range-picker"
                            :linked-calendars="false"
                            @update="changeDate"
                        >
                            <template #input>
                                <em class="fa fa-fw fa-calendar ml-n1"></em>
                                {{ $dayjs(filters.range.startDate).format('D MMMM YYYY') }} -
                                {{ $dayjs(filters.range.endDate).format('D MMMM YYYY') }}
                                <strong class="caret"></strong>
                            </template>
                        </date-range-picker>
                    </div>
                    <div class="col-md-2">
                        <ui-select-multiple
                            id="status"
                            name="status"
                            class="w-100"
                            option-label="key"
                            option-value="value"
                            label="Status"
                            :options="_available_filters.status.map(item => ({ key: item, value: item }))"
                            v-model:value="filters.status"
                        />
                    </div>
                    <div class="col-md-2">
                        <ui-select-multiple
                            id="gateway"
                            name="gateway"
                            class="w-100"
                            option-label="key"
                            option-value="value"
                            label="Gateway"
                            :options="_available_filters.gateway.map(item => ({ key: item.label, value: item.value }))"
                            v-model:value="filters.gateway"
                        />
                    </div>
                    <div class="col-md-1">     
                        <ui-select-multiple
                            id="activityBrandsFilter"
                            name="activityBrandsFilter"
                            label="Brand"
                            class="w-100"
                            option-label="label"
                            option-value="value"
                            :options="brand"
                            v-model:value="filters.brands"
                        />   
                    </div>
                    <div class="col-auto ms-auto" >
                        <ui-button-group class="me-2" v-if="CAN(['read_reports'])">
                            <ui-download-button
                                variant="outline-primary"
                                link="/_/reports/chargebacks"
                                file-extension="csv"
                            />
                            <ui-dropdown variant="outline-primary">
                                <template #button-content>
                                    <i class="fas fa-ellipsis-v"></i>
                                </template>
                                <ui-dropdown-item
                                    link="https://ecartpay.s3.amazonaws.com/templates/chargebacks-template.csv"
                                    download="chargebacks-template"
                                >
                                    Download chargebacks template
                                </ui-dropdown-item>
                            </ui-dropdown>
                        </ui-button-group>
                        <ui-button-group class="me-2" v-if="CAN(['write_chargebacks'])">
                            <ui-button
                                variant="primary"
                                icon="fas fa-upload"
                                @click="showBulkLoadModal({
                                    method: 'post',
                                    title: 'Upload chargebacks in bulk',
                                    url: '/_/chargebacks/batch',
                                })"
                            >
                                Upload
                            </ui-button>
                            <ui-dropdown variant="primary">
                                <template #button-content>
                                    <i class="fas fa-ellipsis-v"></i>
                                </template>
                                <ui-dropdown-item
                                    icon="fas fa-file-download"
                                    @click="showBulkLoadModal({
                                        method: 'put',
                                        title: 'Upload chargeback resolution in bulk',
                                        url: '/_/chargebacks/batch/update',
                                    })"
                                >
                                    Upload chargeback resolution
                                </ui-dropdown-item>
                            </ui-dropdown>
                        </ui-button-group>
                        <bulk-load-modal
                            ref="bulkLoadModal"
                            :method="modalProps.method"
                            :title="modalProps.title"
                            :url="modalProps.url"
                            @bulkUploadCompleted="getChargebacks()"
                        />
                    </div>
                </template>
            </div>
            <ui-table class="mb-4" :items="chargebacks" :fields="fields" :busy="busy" :scopes="['read_chargebacks', 'write_chargebacks']">
                <template #cell(transaction_id)="data">
                    <div>
                        <ui-link v-if="data.item.transaction" :to="`/chargebacks/info?id=${data.item.id}`" >
                            {{ data.item.transaction.transaction_id }}
                        </ui-link>
                    </div>
                    <small>{{data.item.charge_id}}</small>
                </template>
                <template #cell(gateway)="data">
                    <span class="text-uppercase">{{ data.item.gateway }}</span>
                </template>
                <template #cell(account)="data">
                    <span v-if="data.item.transaction">
                        <ui-link weight="medium" :to="{ name: 'accounts-info', query: { id: data.item.account_id } }" target="_blank">
                            <span class="font-weight-600"> {{ data.item.transaction.account.first_name }} {{ data.item.transaction.account.last_name || '' }}</span>
                        </ui-link>
                        <div>
                            <ui-link v-if="data.item.transaction.account.email" underline weight="regular" :link="'mailto:' + data.item.transaction.account.email">
                                <span>{{ data.item.transaction.account.email }}</span>
                            </ui-link>
                            <ui-link v-else-if="data.item.transaction.account.phone" underline weight="regular">
                                {{ data.item.transaction.account.phone }}
                            </ui-link>
                        </div>
                        <ui-badge v-if="data.item.transaction.account.name" variant="success" class="align-self-start badge-large">{{ data.item.transaction.account.name }}</ui-badge>
                    </span>
                    <span v-else class="badge bg-light text-dark">No information</span>
                </template>
                <template #cell(customer)="data">
                    <template v-if="data.item.transaction">
                        <ui-link v-if="data.item.transaction.customer.id" weight="medium" :to="{ name: 'accounts-info', query: { id: data.item.transaction.customer.id } }" target="_blank">
                            <span class="font-weight-600">{{ data.item.transaction.customer.first_name }} {{ data.item.transaction.customer.last_name || '' }}</span>
                        </ui-link>
                        <span v-else>
                            <span v-b-tooltip="'The account is not registered.'" class="fas fa-question-circle text-gray-500"></span>
                            {{ data.item.transaction.customer.first_name }} {{ data.item.transaction.customer.last_name || '' }}
                        </span>
                        <div>
                            <ui-link underline weight="regular" :link="'mailto:' + data.item.transaction.customer.email">
                                <span>{{ data.item.transaction.customer.email }}</span>
                            </ui-link>
                        </div>
                    </template>
                    <span v-else class="badge bg-light text-dark">No information</span>
                </template>
                <template #cell(status)="data">
                    <ui-status
                        v-if="data.item.status"
                        class="d-inline-flex flex-column align-items-center text-capitalize fs-13px"
                        :status="data.item.status"
                    />
                    <span v-else class="badge bg-light text-dark">No information</span>
                </template>
                <template #cell(amount)="data">
                    {{ _currency(data.item.amount, data.item.currency, true) }}
                </template>
                <template #cell(created_at)="data">
                    {{ $dayjs(data.item.created_at).format('LL') }} <br />
                    {{ $dayjs(data.item.created_at).format('LTS') }}
                </template>
                <template #cell(expiration_at)="data">
                    {{ $dayjs(data.item.expiration_at).format('LL') }} <br />
                    {{ $dayjs(data.item.expiration_at).format('LTS') }}
                </template>
                <template #cell(actions)="data">
                    <b-dropdown variant="none" no-caret toggle-class="border text-muted">
                        <template #button-content>
                            <span class="fas fa-ellipsis-v"></span>
                        </template>
                        <b-dropdown-item :link="`/chargebacks/info?id=${data.item.id}`">
                            See details
                        </b-dropdown-item>
                        <div v-if="CAN(['write_chargebacks'])">
                            <b-dropdown-item  @click="changeStatus(data.item.id, data.item.status === 'won' ? 'lost' : 'won')">
                                {{ data.item.status === 'won' ? 'Mark as lost' : 'Mark as won' }}
                            </b-dropdown-item>
                        </div>
                    </b-dropdown>
                </template>
                <template #cellFull="data">
                    <tr v-if="data.item.error">
                        <td class="col-full" :colspan="fields.length">
                            <p class="text-center text-red p-3 mb-0">
                                <span class="fas fa-info-circle"></span>
                                {{ data.item.error }}
                            </p>
                        </td>
                    </tr>
                </template>
            </ui-table>
            <div class="d-flex justify-content-center">
                <ui-pagination v-model="filters.page" :pages="total_pages" class="mt-3" />
            </div>
        </template>
    </div>
</template>

<script>
import DateRangePicker from 'daterange-picker-vue3';
import 'daterange-picker-vue3/dist/daterange-picker-vue3.css';

import BulkLoadModal from '@/components/chargebacks/BulkLoadModal.vue';
import UiButton from '@/components/ui/buttons/Button';
import UiButtonGroup from '@/components/ui/buttons/ButtonGroup';
import UiDropdown from '@/components/ui/dropdown/Dropdown';
import UiDropdownItem from '@/components/ui/dropdown/DropdownItem';
import UiDownloadButton from '@/components/ui/buttons/DownloadButton';
import UiInput from '@/components/ui/Input';
import UiLink from '@/components/ui/Link.vue';
import UiPagination from '@/components/ui/Pagination';
import UiSelectMultiple from '@/components/ui/inputs/SelectMultiple';
import UiStatus from '@/components/ui/BadgeStatus';
import UiTable from '@/components/ui/Table.vue';
import UiTableSkeleton from '@/components/ui/skeleton/TableSkeleton.vue';
import UiBadge from '@/components/ui/Badge';
import table_mixin from '@/mixins/tables.mixin';
import { filters as data_filters } from '@/data';

export default {
    components: {
        BulkLoadModal,
        DateRangePicker,
        UiButton,
        UiButtonGroup,
        UiDropdown,
        UiDropdownItem,
        UiDownloadButton,
        UiInput,
        UiLink,
        UiPagination,
        UiSelectMultiple,
        UiStatus,
        UiTable,
        UiTableSkeleton,
        UiBadge
    },
    mixins: [table_mixin],
    data() {
        const range = {
            startDate: this.$dayjs()
                .subtract(1, 'year')
                .startOf('day')
                .toDate(),
            endDate: this.$dayjs()
                .endOf('day')
                .toDate(),
        };

        return {
            busy: false,
            chargebacks: null,
            creating: false,
            fields: [
                { key: 'transaction_id', label: 'Transaction ID' },
                { key: 'account', label: 'Account' },
                { key: 'customer', label: 'Customer' },
                { key: 'gateway', label: 'Gateway' },
                { key: 'amount', label: 'Amount' },
                { key: 'created_at', label: 'Date' },
                { key: 'expiration_at', label: 'Expiration At' },
                { key: 'status', label: 'Status' },
                { key: 'actions', label: 'Actions' },
            ],
            filters: {
                q: '',
                page: 1,
                limit: 50,
                range: { ...range },
                account: null,
                status: null,
                gateway: null,
                brand: null,
            },
            inititialLoading: true,
            loading: false,
            modalProps: {
                method: '',
                title: '',
                url: '',
            },
            range: { ...range },
            total_pages: null,
        };
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this._changeFilters(this.getChargebacks);
            },
        },
        $route(to) {
            if (to.name === 'chargebacks') {
                this.filters = this._filtersByQuery(this.filters);
            }
        },
    },
    async mounted() {
        await this._getFilters('chargebacks');
    },
    computed: {
        brand() {
            return data_filters.brands;
        },
    },
    methods: {
        showBulkLoadModal(modal_props) {
            this.modalProps = modal_props;
            this.$refs.bulkLoadModal.show();
        },
        changeDate(data) {
            this.filters.range = data;
        },
        async getChargebacks() {
            try {
                if (this.inititialLoading) {
                    this.loading = true;
                }

                this.busy = true;

                const formattedFilters = JSON.parse(JSON.stringify(this.filters));
                const params = {
                    page: formattedFilters.page,
                    limit: formattedFilters.limit,
                    account: formattedFilters.account,
                    status: formattedFilters.status,
                    gateway: formattedFilters.gateway,
                    brand: formattedFilters.brands,
                    start_date: this.$dayjs(formattedFilters.range.startDate).toISOString(),
                    end_date: this.$dayjs(formattedFilters.range.endDate).toISOString(),
                };

                this._clearFilters(params);

                if (String(this.filters.q).trim() !== '') {
                    params.q = this.filters.q;
                }

                const { data } = await this.$axios.get('/_/chargebacks', { params });

                this.total_pages = data.pages;
                this.chargebacks = data.docs;
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                if (this.inititialLoading) {
                    this.loading = false;
                    this.inititialLoading = false
                }

                this.busy = false;
            }
        },
        async changeStatus(id, status) {
            try {
                const { data } = await this.$axios.put(`/_/chargebacks/${id}`, { status });
                const searchIndex = this.chargebacks.findIndex(chargeback => chargeback.id === data.id);

                this.chargebacks.splice(searchIndex, 1, data);
                this.$toast.success('Chargeback updated successfully');
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            }
        },
    },
};
</script>
